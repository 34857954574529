var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-overlay',{staticClass:"w-100",attrs:{"id":"overlay-background","spinner-variant":"primary","show":_vm.isLoading,"variant":"transparent","opacity":"1.00","blur":"0.5em","rounded":"sm"}},[(_vm.faqData !== null)?_c('b-media',{scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"36","text":_vm.avatarText(
              _vm.faqData.user
                ? _vm.faqData.user.name
                : _vm.faqData.customer.nama_customer
            ),"variant":"light-primary"}})]},proxy:true}],null,false,723425646)},[_c('div',{staticClass:"d-flex flex-row"},[_c('h5',{staticClass:"mt-0 font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.faqData.user ? _vm.faqData.user.name : _vm.faqData.customer.nama_customer)+" ")]),_c('span',{staticClass:"mx-1"},[_vm._v(" - ")]),_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(_vm.faqData.created_at))+" ")])]),_c('p',[_vm._v(" "+_vm._s(_vm.faqData.content)+" ")]),_vm._l((_vm.faqData.children),function(child){return _c('b-media',{key:child.id,staticClass:"mb-1",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"36","text":_vm.avatarText(
                child.user !== null
                  ? child.user.name
                  : child.customer.nama_customer
              ),"variant":"light-primary"}})]},proxy:true}],null,true)},[_c('div',{staticClass:"d-flex flex-row"},[_c('h5',{staticClass:"mt-0 font-weight-bolder"},[_vm._v(" "+_vm._s(child.user ? child.user.name : child.customer.nama_customer)+" ")]),_c('span',{staticClass:"mx-1"},[_vm._v(" - ")]),_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(child.created_at))+" ")]),(child.user)?_c('span',{staticStyle:{"font-size":"12px"}},[_c('b-badge',{staticClass:"ml-1",attrs:{"variant":"primary"}},[_vm._v(" Admin ")])],1):_vm._e()]),_c('p',[_vm._v(" "+_vm._s(child.content)+" ")])])})],2):_vm._e()],1),_c('b-form-textarea',{attrs:{"row":"6"},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('div',{staticClass:"d-flex justify-content-end mt-1"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isSendBtnDisabled},on:{"click":_vm.doSend}},[_vm._v(" Kirim "),(_vm.sendLoading)?_c('b-spinner',{staticClass:"ml-1",attrs:{"small":""}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }