<template>
  <b-card>
    <b-overlay
      id="overlay-background"
      spinner-variant="primary"
      :show="isLoading"
      variant="transparent"
      opacity="1.00"
      blur="0.5em"
      rounded="sm"
      class="w-100"
    >
      <b-media v-if="faqData !== null">
        <template #aside>
          <b-avatar
            size="36"
            :text="
              avatarText(
                faqData.user
                  ? faqData.user.name
                  : faqData.customer.nama_customer
              )
            "
            :variant="`light-primary`"
          />
        </template>

        <div class="d-flex flex-row">
          <h5 class="mt-0 font-weight-bolder">
            {{
              faqData.user ? faqData.user.name : faqData.customer.nama_customer
            }}
          </h5>
          <span class="mx-1"> - </span>
          <span>
            {{ formatDate(faqData.created_at) }}
          </span>
        </div>
        <p>
          {{ faqData.content }}
        </p>
        <b-media v-for="child in faqData.children" :key="child.id" class="mb-1">
          <template #aside>
            <b-avatar
              size="36"
              :text="
                avatarText(
                  child.user !== null
                    ? child.user.name
                    : child.customer.nama_customer
                )
              "
              :variant="`light-primary`"
            />
          </template>

          <div class="d-flex flex-row">
            <h5 class="mt-0 font-weight-bolder">
              {{ child.user ? child.user.name : child.customer.nama_customer }}
            </h5>
            <span class="mx-1"> - </span>
            <span>
              {{ formatDate(child.created_at) }}
            </span>
            <span v-if="child.user" style="font-size: 12px">
              <b-badge variant="primary" class="ml-1"> Admin </b-badge>
            </span>
          </div>
          <p>
            {{ child.content }}
          </p>
        </b-media>
      </b-media>
    </b-overlay>
    <b-form-textarea v-model="content" row="6" />
    <div class="d-flex justify-content-end mt-1">
      <b-button variant="primary" :disabled="isSendBtnDisabled" @click="doSend">
        Kirim
        <b-spinner v-if="sendLoading" small class="ml-1" />
      </b-button>
    </div>
  </b-card>
</template>
<script>
import {
  BMedia,
  BAvatar,
  BCard,
  BImg,
  BOverlay,
  BBadge,
  BFormTextarea,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ref, onMounted, watch } from '@vue/composition-api'
import service from '@/components/Table/service'
import { createToastInterface } from 'vue-toastification'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { serializeErrorMessage } from '@core/utils/utils'
import { avatarText, formatDate } from '@core/utils/filter'
import Vue from 'vue'

export default {
  components: {
    BMedia,
    BAvatar,
    BCard,
    BImg,
    BOverlay,
    BBadge,
    BFormTextarea,
    BButton,
    BSpinner,
  },
  setup(props, { root }) {
    const faqData = ref(null)
    const isLoading = ref(false)
    const retrieve = () => {
      isLoading.value = true
      service
        .show({ url: 'faqs', id: root.$route.params.id })
        .then(res => {
          faqData.value = res.data
        })
        .catch(err => {
          const element = Vue.component('description', {
            render: h => serializeErrorMessage(h, err),
          })
          const toast = createToastInterface({
            timeout: 6000,
          })
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error Add Data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              description: element,
            },
          })
        })
        .finally(() => {
          isLoading.value = false
        })
    }
    const content = ref(null)
    onMounted(() => {
      retrieve()
    })
    const sendLoading = ref(false)
    const doSend = () => {
      sendLoading.value = true
      service
        .store({
          url: 'faqs',
          data: {
            content: content.value,
            kd_produk: faqData.value.kd_produk,
            parent_id: root.$route.params.id,
          },
        })
        .then(res => {
          content.value = null
          retrieve()
        })
        .catch(err => {
          const element = Vue.component('description', {
            render: h => serializeErrorMessage(h, err),
          })
          const toast = createToastInterface({
            timeout: 6000,
          })
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error Add Data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              description: element,
            },
          })
        })
        .finally(() => {
          sendLoading.value = false
        })
    }

    const isSendBtnDisabled = ref(true)

    watch(content, newVal => {
      console.log(newVal)
      if (newVal !== '') {
        isSendBtnDisabled.value = false
      } else {
        isSendBtnDisabled.value = true
      }
      console.log(isSendBtnDisabled.value)
    })
    return {
      isLoading,
      faqData,
      avatarText,
      formatDate,
      content,
      sendLoading,
      doSend,
      isSendBtnDisabled,
    }
  },
}
</script>
